import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WebriQForm from "@webriq/gatsby-webriq-form"
import PageHeadingTitle from "@components/pageHeadingTitle"
// import WebriqForm from "../components/form/form"
// import BlockContent from "@sanity/block-content-to-react"

// const serializers = {
//   types: {
//     code: props => (
//       <pre data-language={props.node.language}>
//         <code>{props.node.code}</code>
//       </pre>
//     ),
//   },
// }

const ContactPage = ({ data }) => {
  // const page = data && data.page
  // console.log("data", data)

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle h1={"Equipment"} h2={"Contact"}/>
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          <div className="row justify-content-between ">
            {/* <BlockContent
              blocks={page._rawBody || []}
              serializers={serializers}
            /> */}

            <div className="col-md-6">
              <h2 className="py-3">Fabricating Equipment Sales Co.</h2>
              <p>
                Specializing Exclusively in Structural Steel and Sheet Metal
                Fabricating Equipment.
              </p>
              <p>
                Give us a call and we'll assist in finding the most suitable,
                cost-effective fabricating equipment for your needs.
              </p>

              <div className="page-form">
                <h2 className="py-3">Send us a message:</h2>
                <WebriQForm
                  method="POST"
                  name="Contact Us"
                  data-form-id="4a49f5dd-a902-4265-9a41-0f827b93cb0a"
                  data-thankyou-url="/thank-you"
                  className="contact-us-form"
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Name"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="Email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="subject"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="Message"
                          placeholder="Message"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <div className="webriq-recaptcha" />
                      </div>
                    </div>
                    <div className="form-group col-12">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-red"
                      >
                        {/* <i className="fa fa-envelope mr-2 hvr-icon hvr-icon"></i> */}
                        Send Message
                      </button>
                    </div>
                  </div>
                </WebriQForm>
              </div>
            </div>
            <div className="col-md-6">
              <div id="contact_phone">
                <h2 className="py-3">Contact us</h2>
                <a href="tel:3034667341">Office: (303) 466-7341</a> <br />
                <a href="tel:3035500734">Cell: (303) 550-0734</a> <br />
                <a href="tel:3034652117">Fax: (303) 465-2117</a> <br />
                <p>
                  Email Us:
                  <br />
                  <a href="mailto:Info@FabricatingEquipmentSales.com">
                    Info@FabricatingEquipmentSales.com
                  </a>
                </p>
                <p>
                  Office Hours: <br />
                  Monday - Friday, 7:00 AM - 5:00PM, MST
                </p>
                <p>
                  Mailing Address:
                  <br />
                  PO Box 1904 <br />
                  Broomfield Co. <br />
                  80038-1904
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

// export const query = graphql`
//   query ContactPageQuery {
//     page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
//       id
//       title
//       _rawBody
//     }
//   }
// `

export default ContactPage
